<template>
  <div id="app">
    
    <p v-if="isMobile" class="tip">您正在使用手机登录，请在桌面设备上登录。</p>
    <router-view  v-else/>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isMobile: false
    };
  },
  created() {
    this.checkMobile();
  },
  methods: {
    checkMobile() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      this.isMobile = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent);
    }
  }
};
</script>

<style lang="scss">
#app{
	min-height: 100vh;
	background: #f1f1f1;
}
.tip{
  height: 40px;
  padding: 50px 10px;
}
</style>
