import Vue from "vue";
import App from "./App.vue";
Vue.config.productionTip = false;
import router from "./router";
import store from "./store";
import './assets/scss/reset.scss'
// import * as echarts from 'echarts'
// Vue.prototype.$echarts = echarts
//npm i element-ui -S 并引进
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';


import 'animate.css';

Vue.use(ElementUI)

Vue.prototype.$store = store
import SlideVerify from 'vue-monoplasty-slide-verify';
Vue.use(SlideVerify);

Vue.directive('copy', {
  // 当绑定元素插入到DOM中
  inserted: function (el) {
    el.addEventListener('copy', function (event) {
      event.preventDefault();
    });
  }
});
 
Vue.directive('paste', {
  // 当绑定元素插入到DOM中
  inserted: function (el) {
    el.addEventListener('paste', function (event) {
      event.preventDefault();
    });
  }
});

Vue.directive('removeAriaHidden', {
  bind(el, binding) {
    const ariaEls = el.querySelectorAll('.el-radio__original')
    ariaEls.forEach((item) => {
      item.removeAttribute('aria-hidden')
    })
  }
})


// 判断是否为PC设备，如果是，则跳转到PC版
// const userAgentInfo = navigator.userAgent
// const agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod'];
// const isPc = agents.every(v => userAgentInfo.indexOf(v) < 0);
// if(!isPc){
//   alter('请使用电脑操作！')
// }
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
