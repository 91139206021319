import axios from './axios';
import qs from 'qs';
/* import { isDeclaration } from 'babel-types'; */
export default{

// 登录开始

    // 根据token值查询
getTokenInfo(params){
    return axios.get('/public/personOrCompany/getPersonOrCompanyMsg',{params: params}).then((response) => {
      if (response.status === 200) {
          return response.data;
      } else {
        throw new Error('接口返回失败');
      }
  });
},
// 退出
logout(params){
    return axios.post('/public/loginRegistration/signOut',params).then((response) => {
      if (response.status === 200) {
          return response.data;
      } else {
        throw new Error('接口返回失败');
      }
    });
  },


  // 首页-- 处罚信息查询
  PunishmentInfoList(params){
    return axios.post('/public/punishmentInfoManage/getPunishmentInfoList',params).then(response =>{
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error('接口返回失败');
      }
    })
  },

  //资源管理 - 分页查询课程章节列表
  publicExamChapterList(params){
    return axios.post('/public/publicExamLesson/findTrainingLessonList',params).then(response =>{
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error('接口返回失败');
      }
    })
  },

  // 添加学习记录
 learnRecord(params){
    return axios.post('/public/publicExamLearning/saveOrUpdate',params).then(response =>{
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error('接口返回失败');
      }
    })
  },

  // 查询学习记录列表
  learnList(params){
    return axios.post('/public/publicExamLearning/findPageList',params).then(response =>{
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error('接口返回失败');
      }
    })
  },

  // 加载考试题
  examineLoad(params){
    return axios.post('/public/publicExamExamination/examineLoad',params).then(response =>{
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error('接口返回失败');
      }
    })
  },

  // 开始答题
  startExamin(params){
    return axios.post('/public/publicExamExamination/examineBegin',params).then(response =>{
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error('接口返回失败');
      }
    })
  },
  // 保存答题
  examineSave(params){
    return axios.post('/public/publicExamExamination/examineSave',params).then(response =>{
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error('接口返回失败');
      }
    })
  },

  // 结束答题
  examineEnd(params){
    return axios.post('/public/publicExamExamination/examineEnd',params).then(response =>{
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error('接口返回失败');
      }
    })
  },
  // 查询考试记录列表
  examineRecord(params){
    return axios.post('/public/publicExamExamination/findPageList',params).then(response =>{
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error('接口返回失败');
      }
    })
  },
    //天翼云STS临时访问凭证
    getCtyun(params){
      return axios.get('/public/ctyun/sts',{params:params}).then((response) => {
        if (response.status === 200) {
            return response.data;
        } else {
          throw new Error('接口返回失败');
        }
      });
    },    //保存学习时的截图
    saveLearningImage(params){
      return axios.post('/public/publicExamLearning/saveImage',params).then((response) => {
        if (response.status === 200) {
            return response.data;
        } else {
          throw new Error('接口返回失败');
        }
      });
    },
    // 查询考试窗口期
    getExaminWindowOld(params){
      return axios.post('/public/applyWindowPeriod/isOpen',params,{headers: {'Accept':'*/*','Content-Type': 'application/x-www-form-urlencoded'}}).then((response) => {
        if (response.status === 200) {
            return response.data;
        } else {
          throw new Error('接口返回失败');
        }
      });
    },
    // 查询考试窗口期
    getExaminWindow(params){
      return axios.get('/public/applyWindowPeriod/isOpen',{params:params}).then((response) => {
        if (response.status === 200) {
            return response.data;
        } else {
          throw new Error('接口返回失败');
        }
      });
    },
    // 获取服务器时间
    getTime(params){
      return axios.post('/public/publicExamExamination/getTime',params).then((response) => {
        if (response.status === 200) {
            return response.data;
        } else {
          throw new Error('接口返回失败');
        }
      });
    },
      // 根据UUid或专家id查询专家信息
      userDetailInfo(params){
        return axios.post('/public/expertCheckInManage/getExpertCheckInMsg',params).then(response =>{
          if (response.status === 200) {
            return response.data;
          } else {
            throw new Error('接口返回失败');
          }
        })
      },
       // 查询会员信息
  getMemberInfo(){
    return axios.get('/public/memberInfo/findByToken').then((response) => {
      if (response.status === 200) {
          return response.data;
      } else {
        throw new Error('接口返回失败');
      }
    });
  }
}