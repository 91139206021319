import axios from 'axios';
import Vue from 'vue'
import { MessageBox ,Message  } from 'element-ui'
// import { Loading } from 'element-ui'
// Vue.prototype.$confirm = MessageBox.confirm
// import router from '../router'
// import store from '../store/store.js'
const trainUrl = process.env.VUE_APP_TRAIN_URL
const trainUrl2 = process.env.VUE_APP_TRAIN_URL2
// import netTool from './netTool'
// import './api'
//  配置 baseUrl
// const baseUrl = process.env.ENV_CONFIG === 'dev' ? '': process.env.VUE_APP_API;
const baseUrl = process.env.VUE_APP_API;
// console.log(baseUrl)
// POST传参序列化


const instance = axios.create({
  baseURL: baseUrl,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  },
  withCredentials: false,
  validateStatus: function (status) {
    return status >= 200 && status <= 299;
  }
});
// 声明请求拦截器
// let loadingInstance = null

instance.interceptors.request.use((config) => {
    const token = localStorage.getItem('loginuuid'); // 获取token
    if(token ){
      config.headers.token = token;
    }
    
    const publicToken = localStorage.getItem('publicToken')
    if(publicToken){
      config.headers.publicToken = publicToken;
    }
    return config;
  }, (error) => {
    alert('错误的传参');
    return Promise.reject(error);
  });

  // 返回状态判断
instance.interceptors.response.use((res) => {
    if (res.data.status === 10) { // token 过期或无效时返回状态码10
      Message({
        type: 'warning',
        message: '请您先登录！'
      });
      if(localStorage.getItem('userType') == '3'){
        window.location.href = trainUrl
      }else if(localStorage.getItem('userType') == '7'){
        window.location.href = trainUrl2
      }
      localStorage.removeItem('userType')
      // window.location.href = trainUrl
      return
    }
    return res;
  }, (error) => {
    alert('网络异常');
    return Promise.reject(error);
  });
  
  export default instance;