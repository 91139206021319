
/**
 * 该store使用的是vue简单的store 而不是vuex因此vuex的方法不适用
 * 使用时直接调用this.$store 即可，已经帮定再vue的原型上了。数据就被保存下来
 * 为了保证数据时可以observable  被vue watch到，所以使用了 Vue.observable
 * 
 * 
 * *****/
 import netTool from '../api/netTool'
 import router from '../router'
//  import Vue form 'vue'
 const env = process.env.VUE_APP_ENV_CONFIG
 const trainUrl = process.env.VUE_APP_TRAIN_URL
//  console.log('env',env)
 import { MessageBox  } from 'element-ui'
 import Vue from 'vue'

 let href = window.location.href
    let h = href.split(".")[0]; 
    var erji = h.split("//")[1];
 const store = {
     state:Vue.observable({
         pfId:9998 || JSON.parse(localStorage.getItem('pfId')),
         pfInfo: JSON.parse(localStorage.getItem('pfInfo')) || {},
         userInfo:JSON.parse(localStorage.getItem('userInfo')) || {},
      }), 
     getPfInfo(params){
      netTool.pfInfo(params).then(res => {
        if(res.data != null){
            this.state.pfInfo = res.data;
            document.title = res.data.platformName
            localStorage.setItem('pfInfo',JSON.stringify(res.data))
        }
      })
     },
     getUserInfo(token){
      return new Promise((resolve, reject) =>{
      let params = {
        token:token
        }
        netTool.getTokenInfo(params).then(response => {
          // console.log('res00000',response)
            if(response.success == true && response.data !== null){
                this.state.userInfo = response.data;
                localStorage.setItem('userInfo',JSON.stringify(response.data))
                localStorage.setItem('expertUuid',response.data.personNo)
                resolve(response.data)
            }else{
              // 否则token无效
              localStorage.removeItem('loginuuid')
              // localStorage.removeItem('pfInfo')
              localStorage.removeItem('expertUuid')
              localStorage.removeItem('companyInfo')
              localStorage.removeItem('publicToken')
              localStorage.removeItem('userInfo')
              localStorage.removeItem('auditSatatus')
              localStorage.removeItem('expertInfoId')

              window.location.href = trainUrl

            }
        })
      })
     },
     getMemberInfo(){
      return new Promise((resolve, reject) =>{
        // let params = {
        //   token:token
        //   }
          netTool.getMemberInfo().then(response => {
            // console.log('res00000',response)
              if(response.success == true && response.data !== null){
                  this.state.userInfo = response.data;
                  localStorage.setItem('userInfo',JSON.stringify(response.data))
                  localStorage.setItem('expertUuid',response.data.personNo)
                  resolve(response.data)
              }else{
                // 否则token无效
                localStorage.removeItem('loginuuid')
                // localStorage.removeItem('pfInfo')
                localStorage.removeItem('expertUuid')
                localStorage.removeItem('companyInfo')
                localStorage.removeItem('publicToken')
                localStorage.removeItem('userInfo')
                localStorage.removeItem('auditSatatus')
                localStorage.removeItem('expertInfoId')
  
                window.location.href = trainUrl
  
              }
          })
        })
     },
     gohome(){
      // console.log(route)
      localStorage.removeItem('publicToken')
        if(env == 'prod'){
            router.push({'path':'/'})
        }else{
            
            router.push({'path':'/',query:{
                en:JSON.parse(localStorage.getItem('pfInfo')).platformEnglish
            }})
        }
    },
    logout(){ 
      MessageBox.confirm('您确定要退出吗!', '提示', {
        confirmButtonText: '确定',
        type: 'warning'
      }).then(() => {
        let params = {
          token:localStorage.getItem('uuid')
        }
        netTool.logout(params).then(response => {
            if(response.success == true){
              // removeToken()
              localStorage.removeItem('loginuuid')
              // localStorage.removeItem('pfInfo')
              localStorage.removeItem('expertUuid')
              localStorage.removeItem('companyInfo')
              localStorage.removeItem('publicToken')
              localStorage.removeItem('userInfo')
              localStorage.removeItem('auditSatatus')
              localStorage.removeItem('expertInfoId')
              // this.$router.push({ name: 'login' })
              this.state.userInfo = {}
              // router.push('/')
     
            }
        })
       
      })
    },
    goLoginPage(){
      localStorage.removeItem('loginuuid')
      // localStorage.removeItem('pfInfo')
      localStorage.removeItem('comList')
      localStorage.removeItem('companyInfo')
      localStorage.removeItem('publicToken')
      localStorage.removeItem('userInfo')
      localStorage.removeItem('auditSatatus')
      localStorage.removeItem('expertInfoId')

      window.location.href = trainUrl

    }
 
   }
 export default store
